.copy-to-clipboard {
    border: 1px dotted rgb(88, 29, 29);
    height: 100px;
    width: 100px;
}

.navbar {
    -webkit-backdrop-filter: blur(8px) !important;
    backdrop-filter: blur(8px) !important;
}
