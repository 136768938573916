.header2 {
    font-size: 24px;
    line-height: 24px;
    color: #2b2f31;
}

.refer_header {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #2b2f31;
    margin-bottom: 0px;
}

.rounded_icon {
    width: 48px;
    height: 48px;

    background: #ffffff;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
    border-radius: 30px;
}

.inner_icon {
    position: relative;
    left: calc(50% - 16.5px / 2 + 0.25px);
    top: calc(40% - 16.5px / 2 + 0.25px);
    width: 16.5px;
    height: 16.5px;
}

.number_stat {
    height: 44px;

    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    color: #335eea;
}
